<template>
  <div class="dashboard">
    <div class="_font-size__heading2 _font-weight__600 dashboard-header">Dashboard</div>
    <div class="dashboard-query _flex _flex-align-center">
      <span class="_font-weight__600">Date</span>
      <SelectDate
        type="date"
        v-model="queryFrom.dateTime"
        @change="getData"
        :is-clear="false"
        :disabledDate="disabledDate"
      ></SelectDate>
    </div>

    <div class="dashboard-content">
      <component
        :is="name"
        v-for="name in componentsName"
        :key="name"
        :time="queryFrom.dateTime"
        :detail="detailData"
        :loading="loading"
        class="dashboard-content__item _font-weight__600"
      />
    </div>
  </div>
</template>

<script>
import { dashboard } from '@/api/dashboard'
import { parseTime } from '@/utils'
import common from '@/common'
import SelectDate from '@/components/select-datetime/select-date'

const componentsName = [
  'info',
  'defect-overview',
  'rust-and-sensor',
  'tower-overview',
  'report-list',
  'tower-cameras',
  'model',
  'ground-cameras',
  'alert-history'
]
const components = {
  SelectDate
}
componentsName.forEach((item) => {
  components[item] = () => import(`./components/${item}`)
})
export default {
  name: 'dashboard',
  components,
  data() {
    return {
      queryFrom: {
        dateTime: this.$store.state.user.isOn ? '2023-11-07' : parseTime(new Date(), '{y}-{m}-{d}')
      },
      detailData: {},
      componentsName,
      loading: true
    }
  },
  created() {
    this.getData()
  },
  watch: {
    '$store.state.user.isOn': {
      handler(val) {
        this.queryFrom.dateTime = val ? '2023-11-07' : parseTime(new Date(), '{y}-{m}-{d}')
        this.getData()
      },
      immediate: true
    }
  },
  methods: {
    disabledDate(time) {
      return this.$store.state.user.isOn
        ? !['2023-11-07', '2023-11-08'].includes(parseTime(time, '{y}-{m}-{d}'))
        : false
    },
    getData() {
      dashboard({
        towerCode: common.code,
        dateTime: parseTime(this.queryFrom.dateTime, '{y}-{m}-{d}')
      }).then((res) => {
        this.detailData = res.result || {}
        this.queryFrom.dateTime =
          res.result && res.result.dataDateStr ? res.result.dataDateStr : this.queryFrom.dateTime
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &-header {
    margin-bottom: 10px;
  }
  &-query {
    line-height: 28px;
    margin-bottom: 15px;
    > span {
      margin-right: 20px;
      font-size: 12px;
    }
    ::v-deep .date-picker {
      width: 100px;
    }
  }

  &-content {
    width: 100%;
    border-radius: 10px;
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: 365px 330px 400px calc(100% - 365px - 330px - 400px - 20px * 3);
    grid-template-rows: 250px 285px 280px;

    &__item {
      background: var(--color-white);
      border-radius: 10px;
      padding: 20px;

      ::v-deep .title-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &__icon {
          font-weight: 600;
          display: inline-flex;
          align-items: center;

          > img {
            width: 32px;
            height: 32px;
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }

    & .defect-overview {
      grid-column-start: 2;
      grid-column-end: 4;
    }

    & .rust-and-sensor {
      background: rgba(0, 0, 0, 0);
      padding: 0;
    }
    & .tower-overview {
      grid-row-start: 2;
      grid-row-end: 4;
    }

    & .tower-cameras {
      grid-column-start: 3;
      grid-column-end: 5;
    }
  }
}
</style>
